const ignoredExceptions = [
	'ErrorEvent: ResizeObserver loop limit exceeded',
	'ErrorEvent: ResizeObserver loop completed with undelivered notifications.',
	'PromiseRejectionEvent: AbortError: The user aborted a request.',
];

interface BootInfo {
	currentUser: { id: string };
}

declare global {
	interface DocumentEventMap {
		boot: CustomEvent<BootInfo>;
	}
}

export const initAppInsights = async (azureConnectionString: string) => {
	try {
		const { ApplicationInsights } = await import('@bundle/appinsights');
		const appInsights = new ApplicationInsights({
			config: {
				connectionString: azureConnectionString,
				enableUnhandledPromiseRejectionTracking: true,
			},
		});
		appInsights.loadAppInsights();
		appInsights.trackPageView();
		appInsights.addTelemetryInitializer((envelope) => {
			if (ignoredExceptions.includes(envelope.data?.message)) {
				return false;
			}
		});
		document.addEventListener('boot', ({ detail }) => {
			const validatedId = detail.currentUser.id.replace(/[,;=| ]+/gu, '_');
			appInsights.setAuthenticatedUserContext(validatedId, undefined, true);
		});
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('Failed to load application insights!');
		// eslint-disable-next-line no-console
		console.error(e);
	}
};
